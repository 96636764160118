<template>
  <el-dialog
    v-model="dialogVisible"
    :title="`${cusProps.action === 'create' ? '新增' : '編輯'}套餐`"
    width="880px"
    :close-on-click-modal="false"
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="meal-form"
      :rules="rules"
    >
      <el-form-item class="form-item" prop="name">
        <template #label>
          <span class="label">
            套餐全名
            <span class="tips">顯示於明細(上限15字)</span>
          </span>
        </template>
        <el-input v-model="ruleForm.name" placeholder="請輸入套餐全名" maxlength="15" show-word-limit />
      </el-form-item>
      <el-form-item class="form-item" prop="short_name">
        <template #label>
          <span class="label">
            套餐簡稱
            <span class="tips">顯示於POS按鈕(上限8字)</span>
          </span>
        </template>
        <el-input v-model="ruleForm.short_name" placeholder="請輸入套餐簡稱" maxlength="8" show-word-limit />
      </el-form-item>
      <el-form-item class="form-item" prop="available_shop_type">
        <template #label>
          <span class="label">門市</span>
          <el-radio-group v-model="ruleForm.available_shop_type" style="margin-left: 15px; transform: translateY(1px)">
            <el-radio v-for="item in available_shop_type_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-select
          v-if="ruleForm.available_shop_type === 2"
          v-model="ruleForm.available_shop_value_array"
          multiple
          placeholder="請選擇區域"
          style="width: 100%"
        >
          <el-option v-for="item in area_options" :key="item.label" :label="item.label" :value="item.value" />
        </el-select>
        <upload-file
          v-if="ruleForm.available_shop_type === 3"
          v-model:file_url="ruleForm.available_shop_file_url"
          v-model:file_name="ruleForm.available_shop_file_name"
        ></upload-file>
      </el-form-item>
      <el-form-item class="form-item" prop="time_period_type">
        <template #label>
          <span class="label">套餐日期</span>
          <el-radio-group
            v-model="ruleForm.time_period_type"
            style="margin-left: 15px; transform: translateY(1px)"
            @change="clearItemValidate(ruleFormRef, 'time_period_type')"
          >
            <el-radio v-for="item in time_period_type_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-date-picker
          v-if="ruleForm.time_period_type === 2"
          v-model="datetimeRange"
          type="datetimerange"
          range-separator="－"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          format="YYYY/MM/DD HH:mm"
          value-format="YYYY/MM/DD HH:mm"
          :default-time="defaultTime"
        />
      </el-form-item>
      <!-- 價格part -->
      <el-form-item class="form-item" prop="prices">
        <template #label>
          <span class="label">價格模式</span>
        </template>
        <el-select v-model="selectPrice" placeholder="請選擇區域" style="width: 100%" clearable>
          <el-option v-if="ruleForm.prices.length !== getPriceSet.length" label="全選" value="all"></el-option>
          <el-option
            v-for="item in getPriceSet"
            :key="item.price_set_id"
            :label="item.price_name"
            :value="item.price_name + ' ' + item.price_set_id"
            :disabled="checkPrice(item)"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" prop="inputPrice">
        <template #label>
          <span class="label">價格</span>
        </template>
        <el-input v-model="inputPrice" placeholder="請輸入價格" type="number" :disabled="!selectPrice">
          <template #append>元</template>
        </el-input>
      </el-form-item>
      <div class="price-list" style="grid-area: 4/1/5/3">
        <el-button
          style="width: 100%; margin-bottom: 8px"
          :disabled="!selectPrice || !inputPrice || Number(inputPrice) < 0"
          @click="handleAddPrice"
        >
          新增價格模式
          <el-icon style="margin-left: 5px"><CirclePlus /></el-icon>
        </el-button>
        <el-table
          :data="ruleForm.prices"
          style="width: 100%"
          empty-text="請按「新增價格模式」加入項目，至少新增一項"
          class="price-table"
          header-cell-class-name="price-table-header"
        >
          <el-table-column prop="name" label="區域" />
          <el-table-column prop="price" label="價格" width="120" />
          <el-table-column width="80">
            <template #default="scope">
              <el-button link type="danger" size="small" @click.prevent="handleDeletePrice(scope.$index)">
                刪除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-form-item class="form-item" style="grid-area: 5/1/6/3">
        <template #label>
          <span class="label">備註</span>
        </template>
        <el-input v-model="ruleForm.remark" placeholder="請輸入備註" />
      </el-form-item>
      <el-divider style="grid-area: 6/1/7/3" />
      <div style="grid-area: 7/1/8/3">
        <meal-product ref="productFormRef" v-model:form="ruleForm.product"></meal-product>
      </div>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleSubmit()" style="margin-left: 25px">
          {{ cusProps.action === 'create' ? '新增' : '儲存' }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref, watch, reactive, defineAsyncComponent, getCurrentInstance } from 'vue';
import { time_period_type_options, available_shop_type_options, area_options, mapOptionsLabel } from '@/utils/define';
import { formatDateTime } from '@/utils/datetime';
import { useGetters, useActions } from '@/utils/mapStore';
export default {
  name: 'meal-dialog',
  components: {
    MealProduct: defineAsyncComponent(() => import('@/components/meal/MealProduct.vue')),
    UploadFile: defineAsyncComponent(() => import('@/components/common/UploadFile.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const submit = inject('submit');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const productFormRef = ref(null);
    const ruleForm = ref(null);
    const datetimeRange = ref(null);
    const selectPrice = ref(null);
    const inputPrice = ref(null);
    //設定初始值
    const doGetProductOptions = useActions('meal', ['doGetProductOptions']);
    if (dialogVisible) {
      if (cusProps.init) {
        ruleForm.value = JSON.parse(JSON.stringify(cusProps.init));
        //日期格式
        if (ruleForm.value.time_period_type === 2) {
          datetimeRange.value = [];
          ruleForm.value.available_start_time = formatDateTime('YYYY/MM/DD HH:mm', ruleForm.value.available_start_time);
          ruleForm.value.available_end_time = formatDateTime('YYYY/MM/DD HH:mm', ruleForm.value.available_end_time);
          datetimeRange.value.push(ruleForm.value.available_start_time);
          datetimeRange.value.push(ruleForm.value.available_end_time);
        }
        //商品index與刪除array
        let initIndex = 1;
        ruleForm.value.product.forEach(async (e) => {
          e.index = initIndex;
          delete e.size;
          delete e.temperature;
          delete e.sweetness;
          initIndex++;
          const options = await doGetProductOptions(e.product_id);
          e.options = options;
        }); //取得第一個商品的選項
      }
      //套餐基本參數
      else
        ruleForm.value = {
          name: '',
          short_name: '',
          event_id: null,
          time_period_type: 1,
          available_start_time: null,
          available_end_time: null,
          remark: '',
          available_shop_type: 1,
          available_shop_value_array: [],
          available_shop_file_url: null,
          available_shop_file_name: null,
          prices: [],
          product: [
            {
              index: 1,
              product_name: '',
              product_id: '',
              amount: '',
              size: '',
              temperature: '',
              sweetness: '',
              replace_product_list: [],
              options: null,
            },
          ],
        };
    }
    //價格模式
    const getPriceSet = useGetters('common', ['getPriceSet']);
    //增加價格模式到料表中
    const handleAddPrice = () => {
      //全選
      if (selectPrice.value === 'all') {
        ruleForm.value.prices = [];
        getPriceSet.value.forEach((e) => {
          ruleForm.value.prices.push({
            name: e.price_name,
            price_set_id: e.price_set_id,
            price: Number(inputPrice.value),
          });
        });
      } else {
        ruleForm.value.prices.push({
          name: selectPrice.value.split(' ')[0],
          price_set_id: selectPrice.value.split(' ')[1],
          price: Number(inputPrice.value),
        });
      }
      selectPrice.value = null;
      inputPrice.value = null;
      clearItemValidate(ruleFormRef.value, 'prices');
    };
    //從列表中刪除價格模式
    const handleDeletePrice = (index) => {
      ruleForm.value.prices.splice(index, 1);
    };
    //radio切換-清除單項錯誤提示
    const clearItemValidate = (form, val) => {
      form.clearValidate([val]);
    };
    //檢查該價格模式有沒有被選中
    const checkPrice = (item) => {
      let isSelect = false;
      ruleForm.value.prices.forEach((e) => {
        if (e.price_set_id === item.price_set_id) isSelect = true;
      });
      return isSelect;
    };
    watch(
      () => [
        ruleForm.value.available_shop_type,
        ruleForm.value.available_shop_value_array,
        ruleForm.value.available_shop_file_url,
      ],
      () => {
        clearItemValidate(ruleFormRef.value, 'available_shop_type');
      }
    );
    //日期相關
    watch(
      () => datetimeRange.value,
      () => {
        if (!datetimeRange.value) {
          ruleForm.value.available_start_time = null;
          ruleForm.value.available_end_time = null;
        } else if (datetimeRange.value.length > 0) {
          ruleForm.value.available_start_time = datetimeRange.value[0];
          ruleForm.value.available_end_time = datetimeRange.value[1];
        }
      }
    );
    const defaultTime = [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 0)];
    //驗證
    const validateTime = (rule, value, callback) => {
      if (ruleForm.value.time_period_type === 1) callback();
      else {
        if (!datetimeRange.value) return callback(new Error('請選擇日期'));
        else callback();
      }
    };
    const validateShop = (rule, value, callback) => {
      switch (value) {
        case 1:
          callback();
          break;
        case 2:
          if (ruleForm.value.available_shop_value_array.length === 0) return callback(new Error('請選擇區域'));
          else callback();
          break;
        case 3:
          if (!ruleForm.value.available_shop_file_name || !ruleForm.value.available_shop_file_url)
            return callback(new Error('請選擇檔案'));
          else callback();
          break;
      }
    };
    const validatePrice = (rule, value, callback) => {
      if (value.length === 0) return callback(new Error('請至少選擇一個價格模式'));
      else callback();
    };
    const validateInputPrice = (rule, value, callback) => {
      if (Number(inputPrice.value) < 0) return callback(new Error('不能為負數'));
      else callback();
    };
    const rules = reactive({
      name: [{ required: true, message: '請輸入套餐全名', trigger: 'blur' }],
      short_name: [{ required: true, message: '請輸入套餐簡稱', trigger: 'blur' }],
      time_period_type: [{ validator: validateTime, trigger: 'blur' }],
      available_shop_type: [{ validator: validateShop, trigger: 'blur' }],
      prices: [{ validator: validatePrice, trigger: 'blur' }],
      inputPrice: [{ validator: validateInputPrice, trigger: 'blur' }],
    });

    //送出
    const doSaveMeal = useActions('meal', ['doSaveMeal']);
    const handleSubmit = async () => {
      try {
        const valid = await ruleFormRef.value.validate().catch((e) => console.error(e));
        //觸發MealProduct的validateForm
        const valid2 = await productFormRef.value.validateForm().catch((e) => console.error(e));
        if (!valid || !valid2) return;
        await doSaveMeal(ruleForm.value);
        if (cusProps.action === 'create' || cusProps.action === 'edit') {
          proxy.$message({
            type: 'success',
            message: `${cusProps.action === 'create' ? '新增' : '修改'}成功`,
          });
        }
        submit({ action: cusProps.action, event: ruleForm.value });
      } catch (e) {
        e;
      }
    };
    return {
      time_period_type_options,
      available_shop_type_options,
      area_options,
      mapOptionsLabel,
      dialogVisible,
      close,
      submit,
      cusProps,
      ruleFormRef,
      productFormRef,
      ruleForm,
      datetimeRange,
      selectPrice,
      inputPrice,
      getPriceSet,
      handleAddPrice,
      handleDeletePrice,
      checkPrice,
      defaultTime,
      rules,
      clearItemValidate,
      handleSubmit,
    };
  },
};
</script>
<style lang="scss" scoped>
.meal-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 120px 1fr auto 1fr 45px auto;
  grid-column-gap: 25px;
}
.form-item {
  @include basic-form-item;
}
.dialog-footer {
  @include full-button;
}
.el-divider--horizontal {
  margin: 4px 0 24px;
}

.price-list {
  width: 100%;
  margin: 0 auto 30px;
  :deep(.price-table) {
    border: 1px solid $el-border-color-lighter !important;
    border-bottom: none !important;
    .price-table-header {
      font-weight: 500;
      color: $color-text-light;
      background-color: $color-background !important;
    }
  }
}
</style>
